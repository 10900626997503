import React from 'react';

import { Layout } from '../modules/ui';
import { AnalysisPage } from '../modules/analysis-page';
import Seo from '../components/SEO';
import * as Constants from '../constants';

const AppWorkshopPage = () => {
    const footerRef = React.useRef();

    return (
        <>
            <Seo pageId={Constants.pages.WORKSHOP} />
            <Layout footerRef={footerRef}>
                <AnalysisPage footerRef={footerRef} />
            </Layout>
        </>
    );
};

export default AppWorkshopPage;
